import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Loader from './components/loader/Loader';
const Career = React.lazy(() => import('./page/Career'));
const HomePage = React.lazy(() => import('./page/HomePage'));
const ContactUs = React.lazy(() => import('./page/Contact-us'));
const TermsAndConditions = React.lazy(() => import('./page/TermsAndconditions'));
const DevelopmentProcess = React.lazy(() => import('./page/DevelopmentProcess'));
const ProjectEstimate = React.lazy(() => import('./page/ProjectEstimate'));
const PrivacyPolicy = React.lazy(() => import('./page/PrivacyPolicy'))
const Kirat = React.lazy(() => import('./page/Kirat'));
const Sujosu = React.lazy(() => import('./page/Sujosu'));

export default function RouteManager() {
  return (
    <>
      <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <React.Suspense fallback={<div><Loader /></div>}>
                    <HomePage />
                  </React.Suspense>
                }
              />
              <Route
                path="Contact"
                element={
                  <React.Suspense fallback={<div><Loader /></div>}>
                    <ContactUs />
                  </React.Suspense>
                }
              />
              <Route
                path="Career"
                element={
                  <React.Suspense fallback={<div><Loader /></div>}>
                    <Career />
                  </React.Suspense>
                }
              />


              <Route
                path="ProjectEstimate"
                element={
                  <React.Suspense fallback={<div><Loader /></div>}>
                    <ProjectEstimate />
                  </React.Suspense>
                }
              />
              <Route
                path="DevelopmentProcess"
                element={
                  <React.Suspense fallback={<div><Loader /></div>}>
                    <DevelopmentProcess />
                  </React.Suspense>
                }
              />
            
              <Route
                path="TermsAndConditions"
                element={
                  <React.Suspense fallback={<div><Loader /></div>}>
                    <TermsAndConditions/>
                  </React.Suspense>
                }
              />
               <Route
                path="PrivacyPolicy"
                element={
                  <React.Suspense fallback={<div><Loader /></div>}>
                    <PrivacyPolicy/>
                  </React.Suspense>
                }
              />
            
            <Route
            path="Kirat"
            element={
              <React.Suspense fallback={<div><Loader /></div>}>
                <Kirat />
              </React.Suspense>
            }
          />
          <Route
          path="Sujosu"
          element={
            <React.Suspense fallback={<div><Loader /></div>}>
              <Sujosu />
            </React.Suspense>
          }
        />
            </Routes>
          </BrowserRouter>
    </>
  )
}
