import './Loader.css'
function Loader() {
    return ( 
    <>
<div id="loading" className="loading-visible">
  <div className="loading-center">
      <div className="loading-center-absolute">
          <div className="object" id="object_four">
          </div>
          <div className="object" id="object_three">
          </div>
          <div className="object" id="object_two">
          </div>
         
      </div>
      
  </div>
</div>

    </> )
}

export default Loader;











