
import React from 'react';
import RouteManager from './RouteManager';
import {

  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>

        <RouteManager />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>

    </>

  );
}

export default App;
